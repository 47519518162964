<template>
  <img v-if="loaded" :src="imgSrc" @error="errorHandler" :alt="altTag" />
</template>
<script>
import { IMAGE_URL } from "../config";
import ElectricDriverApi from "Services/ElectricDriverApi";
export default {
  props: {
    vehicle: Object,
    imgType: String,
    imgSizeType: String, //Thumbnail, Medium, Wide
    defaultImg: {
      type: String,
      default: `${IMAGE_URL}/photo_unavailable.jpg`,
    },
  },
  data() {
    return { error: false, imgUrl: "", loaded: false };
  },
  async mounted(){
    await this.getVehicleImageUrl();
  },
  computed: {
    imgSrc() {
      if (!this.error && this.imgUrl) {
        return this.imgUrl;
      } else {
        return this.defaultImg;
      }
    },
    altTag() {
      return `${this.vehicle.Year} ${this.vehicle.Make} ${this.vehicle.Model} ${this.vehicle.Trim} ${this.imgType}`;
    },
  },
  watch: {
    vehicle: {
      handler: async function () {
        await this.getVehicleImageUrl();
      },
      deep: true,
    },
  },
  methods: {
    async getVehicleImageUrl() {
      this.loaded = false;
      this.imgUrl = await ElectricDriverApi.getVehicleImageUrl(this.vehicle, this.imgType, this.imgSizeType);
      if (!this.imgUrl) {
        this.errorHandler();
      }
      this.loaded = true;
    },
    errorHandler() {
      this.$emit("image-not-found", this.imgType);
      this.error = true;
    },
  },
};
</script>
